.contact__map {
  position: relative;
  padding-top: 68px;
  z-index: 1;
}

.contact__form {
  position: absolute;
  border-radius: 10px;
  background-color: rgba(37,121,181,.4);
  padding: 20px;
  box-shadow: 0 1px 3px #c1c1c1;
  width: 40%;
  top: 80px;
  right: 10%;
  z-index: 9;

  @media screen and (max-width: 991px) {
    width: 80%;
  }
}


textarea.ninja-forms-field{
  max-height: 100px;
  min-width: 100%;
  max-width: 100%;
}

.nf-field-label, .nf-form-title, .nf-form-fields-required {
  color: rgb(239,239,238);
}

.nf-response-msg > p {
  text-align: center;
  color: white !important;
  font-size: 30px !important;
}