.section--footer {
  background-color: $color-main;
  padding-top: 30px;
  padding-bottom: 0;
  color: $color-bg;

  p {
    color: $color-bg;
  }
}

p.page-sub-title {
  font-size: 25px;
  font-weight: bold;
  color: $color-brand-2;
  position: relative;
  margin-bottom: 30px;

  &::before {
    position: absolute;
    bottom: -10px;
    left: 0;
    content: '';
    width: 50px;
    height: 2px;
    background-color: $color-brand-2;
  }
}

.let {
  padding-left: 0;

  > li {
    list-style-type: none;
    display: block;
    margin-bottom: 10px;

    > a {
      color: $color-bg;
      font-size: 1.6rem;
      display: inline-block;
      position: relative;

      ._icon {
        margin-right: 10px;
      }

      &::before {
        content: '';
        position: absolute;
        bottom: -5px;
        left: 15px;
        width: 0;
        height: 3px;
        background-color: $color-brand-2;
        transition: width 200ms ease-in-out;
      }

      &:hover {

        &::before {
          width: 100%;
        }
      }
    }
  }
}

.footer-contact > p > i, .footer-contact >p >a {
  color: $color-bg;
}

.social {
  margin-top: 20px;
  padding: 0;

  > li {
    list-style-type: none;
    float: left;
    border: 1px solid $color-bg;
    width: 45px;
    height: 45px;
    text-align: center;
    line-height: 45px;
    border-radius: 50%;
    transition: background 200ms;

    &:not(:first-of-type) {
      margin-left: 15px;
    }

    > a {
      font-size: 2.2rem;
      color: inherit;
      transition: color 200ms;
    }

    &:hover {
      background-color: $color-bg;
      transition: background 200ms;

      > a {
        color: $color-main;
      }
    }
  }
}

.footer-copy {
  margin-top: 20px;
  padding: 20px 0;
  text-align: center;
  color: $color-bg;
  background-color: $color-brand;

  p {
    margin: 0;
    font-size: 12px;
    text-align: left;
  }
}

.scroll-top {
  position: fixed;
  width: 40px;
  height: 40px;
  line-height: 40px;
  right: 20px;
  bottom: 100px;
  z-index: 9997;
  display: inline-block;
  cursor: pointer;
  text-align: center;
  font-size: 2rem;
  color: $color-main;
  border-radius: 50%;
  border: 2px solid $color-main;
}