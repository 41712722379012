.header__logo {
  padding: 0;
  outline-style: none;
  height: 68px;

  & > img {
    max-height: 68px;
  }
}

.header__nav {
  background-color: $color-bg;
  box-shadow: 0 1px 3px $color-shadow;
  opacity: .9;
}

.nav-opacity {
  opacity: .68;
}

.nav {

  & > li > a {
    color: $color-main;
    padding: 20px 20px;
    display: inline-block;
    text-transform: uppercase;
    height: 100%;
    width: 100%;
    line-height: 25px;
    font-size: 18px;
    font-weight: 600;
    transition: .5s;
  }

  & > li > a:hover {
    color: $color-main !important;
  }
}
.search__nav {
  position: relative;
  i {
    font-size: 25px;
    cursor: pointer;
  }
  .form-search-top {
    position: absolute;
    right: 0;
    min-width: 300px;
    max-width: 100%;
    padding: 10px;
    background-color: rgba(255,255,255,.7);
    border-radius: 5px;
    display: none;
  }
  input[type=text] {
    width: 170px;
    box-sizing: border-box;
    border: 2px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
    background-color: white;
    background-image: url('/wp-content/themes/my-theme/assets/img/icon/searchicon.png');
    background-position: 10px 10px;
    background-repeat: no-repeat;
    padding: 8px 20px 8px 40px;
    -webkit-transition: width 0.4s ease-in-out;
    transition: width 0.4s ease-in-out;
  }

  input[type=text]:focus {
    width: 100%;
  }
}