.interns__box {
  padding-top: 50px;
  outline-style: none;

  .interns__img{
    width: 150px;
    height: 150px;
    margin: 0 auto;

    & > img {
      width: 100%;
      height: 100%;
      min-height: 150px;
      border-radius: 50%;
      border: 3px solid $color-bg;
    }
  }

  & > .interns__title {
    color: $color-bg;
    font-size: 26px;
    text-transform: uppercase;
    line-height: 32px;
    padding-top: 20px;
    text-align: center;
  }

  & > p {
    color: $color-bg;
    font-size: 15px;
    line-height: 25px;
    text-align: justify;
    padding: 0 10px;

    .interns__sub {
      text-align: justify;
    }
  }
}

.video__info {

  & > h3 {
    color: $color-main;
  }

  & > p {
    text-align: justify;
  }
}
