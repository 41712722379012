.slick-slider {
  position: relative;
  display: block;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {
  position: relative;
  display: block;
  overflow: hidden;
  margin: 0;
  padding: 0;
}

.slick-list:focus {
  outline: none;
}

.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  top: 0;
  left: 0;

  display: block;
}

.slick-track:before,
.slick-track:after {
  display: table;
  content: '';
}

.slick-track:after {
  clear: both;
}

.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  display: none;
  float: left;
  height: 100%;
  min-height: 1px;
}

[dir='rtl'] .slick-slide {
  float: right;
}

.slick-slide img {
  display: block;
  min-height: 200px;
}

.slick-slide.slick-loading img {
  display: none;
}

.slick-slide.dragging img {
  pointer-events: none;
}

.slick-initialized .slick-slide {
  display: block;
}

.slick-loading .slick-slide {
  visibility: hidden;
}

.slick-vertical .slick-slide {
  display: block;

  height: auto;

  border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
  display: none;
  padding: 10px;
}

@font-face {
  font-family: 'slick';
  font-weight: normal;
  font-style: normal;

}

.slick-prev,
.slick-next {
  font-size: 0;
  line-height: 0;
  position: absolute;
  top: 50%;
  display: block;
  width: 20px;
  height: 20px;
  margin-top: -10px;
  padding: 20px;
  cursor: pointer;
  color: transparent;
  border: none;
  outline: none;
  opacity: .5;
}

.slick-prev {
  background-image: url("http://az-tuyendung.dev/wp-content/themes/startter/assets/img/icon/back.png");

  @media screen and (max-width: 840px) {
    background-image: none;
  }
}

.slick-next {
/*  background-image: url("/wp-content/themes/startter/assets/img/icon/next.png");*/

  @media screen and (max-width: 840px) {
    background-image: none;
  }

}

.slick-prev:hover,
.slick-prev:focus,
.slick-next:hover,
.slick-next:focus {
  color: transparent;
  outline: none;
  opacity: .9;
}

.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before {
  opacity: .25;
}

.slick-prev:before,
.slick-next:before {
  font-family: 'slick';
  font-size: 40px;
  line-height: 1;
  opacity: .75;
  color: white;
  position: absolute;
  left: 0;
  top: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.slick-prev {
  left: -40px;
}

[dir='rtl'] .slick-prev {
  right: -25px;
  left: auto;
}



.slick-next {
  right: -40px;


}

[dir='rtl'] .slick-next {
  right: auto;
  left: 10px;
}

.slick-slider {
  margin-bottom: 30px;
}

.slick-dots {
  position: absolute;
  bottom: 10px;
  display: block;
  width: 100%;
  padding: 0;
  list-style: none;
  text-align: center;
  & li {
    position: relative;

    display: inline-block;

    width: 20px;
    height: 20px;
    margin: 0 5px;
    padding: 0;

    cursor: pointer;
  }
  & li button {
    font-size: 0;
    line-height: 10px;

    display: block;

    width: 20px;
    height: 20px;
    padding: 5px;

    cursor: pointer;

    color: transparent;
    border: 0;
    outline: none;
    background: transparent;
  }

  & li button:hover {
    outline: none;
  }

  & li button:focus {
    outline: none;
  }
}

.slick-dots li button:before
{
  font-family: 'slick';
  font-size: 30px;
  line-height: 20px;

  position: absolute;
  top: 0;
  left: 0;

  width: 20px;
  height: 20px;

  content: '•';
  text-align: center;

  opacity: .25;
  color: $color-main;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


.slick-dots li.slick-active button:before {
  opacity: .75;
  color: $color-main;
}

.auto-slider-banner {
  img {
    margin: 0 auto;
    max-height: 600px;
  }
}

.box__slider {
  position: relative;
  background-color: $color-main;
  z-index: -1;
}

.img-relative {
  position: relative;
  z-index: -1;
  max-height: 750px;
  width: auto;
  margin: 0 auto;
  box-shadow: -5px 5px 10px 5px rgba(50,60,87,0.5);
}

.content__slider {
  width: 70%;
  border-radius: 5px 50px;
  top: 3%;
  left: 15%;
  text-align: center;
  position: absolute;
  z-index: 99;
  padding: 25px 10px;
  background-color: rgba(79,169,216,0.35);

  @media screen and (max-width: 768px){
    width: 90%;
    left: 5%;
  }

  @media screen and (min-width: 1337px){
    width: 50%;
    left: 25%;
  }

  @media screen and (min-width: 1800px){
    width: 40%;
    left: 30%;
  }

  & > .content__slider__title {
    color: $color-bg;
    font-size: 45px;
    margin-bottom: 0;
    text-transform: uppercase;
    font-weight: 600;

    @media screen and (max-width: 768px){
      font-size: 25px;
    }
  }

  & > .content__slider__sub {
    font-size: 20px;
    padding: 10px 0;
    color: $color-bg;

    @media screen and (max-width: 768px) {
      padding: 0;
      font-size: 14px;
      padding: 5px 0;
    }
  }
}

#main-slider {
  box-shadow: 2px 2px 5px $color-text;
}

.slider-banner {
  & > .slick-next {
    right: 20px;
    width: 50px;
    height: 50px;

    &::before {
     font-size: 50px;
    }
  }
  & > .slick-prev {
    left: 20px;
    z-index: 9;

    &::before {
      font-size: 50px;
    }
  }
}