.btn-primary {
  box-shadow: inset 0 0 20px rgba(255, 255, 255, 0);
  border: 1px solid $color-bg;
  border-radius: 0;
  outline: 1px solid $color-bg;
  outline-color: rgba(255, 255, 255, 0.5);
  outline-offset: 0px;
  text-shadow: none;
  transition: all 1250ms cubic-bezier(0.19, 1, 0.22, 1);
  font-size: 16px;
  padding: 10px 30px;
  background-color: $color-main;
  text-transform: uppercase;
  font-weight: 600;

  &:hover, &:focus, &:active {
    outline-offset: 15px;
    outline: 0px solid $color-bg;
    border: 1px solid $color-bg;
    box-shadow: inset 0 0 20px rgba(255, 255, 255, 0.5), 0 0 20px rgba(255, 255, 255, 0.2);
    outline-color: rgba(255, 255, 255, 0);
    text-shadow: 1px 1px 2px #427388;
    background-color: $color-main;
  }
}

.btn-sidebar {
  width: 100%;
  padding: 10px 20px;
  margin-top: 10px;
  background-color: $color-main;
  border-color: $color-main;
  color: $color-bg;
  font-size: 16px;
  font-weight: 600;
  transition: .4s;
  &:hover, &:focus {
    background-color: $color-bg;
    border-color: $color-main;
    color: $color-main;
  }
}

.btn-sidebar-sub {
  width: 100%;
  padding: 10px 20px;
  margin-top: 10px;
  background-color: $color-bg;
  border-color: $color-main;
  color: $color-main;
  font-size: 16px;
  font-weight: 600;
  transition: .4s;

  &:hover, &:focus {
    background-color:  $color-gray;
    border-color: $color-main;
    color: $color-main;
  }
}

.btn-readmore {
  display: block;
  width: 100%;
  padding-top: 20px;
  text-align: center;

  @media screen and(max-width: 768px){
    width: 100%;
    text-align: center;
  }
}

.utn-fixed {
  display: block;
  position: fixed;
  bottom: 0;
  left: 40%;
  width: 20%;
  z-index: 9997;
  border-color: $color-bg;
  margin-bottom: 10px;
  opacity: 0.8;

  @media screen and(max-width: 991px){
    left: 30%;
    width: 40%;
  }

  @media screen and(max-width: 768px){
    left: 0;
    width: 100%;
    margin-bottom: 0;
  }

  &:hover {
    opacity: 1;
  }
}

.btn-hide {
  display: none;
}

.btn-show {
  display: block;
}