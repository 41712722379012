body {
  overflow-x: hidden;
  font-family: 'Montserrat', sans-serif;
}

p {
  color: $color-text;
  font-size: 14px;
  line-height: 1.75;
}

p.large {
  font-size: 16px;
}

a, a.active, a:active, a:focus, a:hover {
  outline: none;
  outline-style: none;
  text-decoration: none;
}

a {
  color: $color-main;
}

a.active,
a:active,
a:focus,
a:hover {
  color: $color-brand;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 600;
}



html {
 /* background: url("http://az-tuyendung.dev/wp-content/themes/starter/assets/img/TimeAlone-1.jpg") no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;*/
}

section {
  padding-top: 70px;
}
