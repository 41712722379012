#background-popup {
  z-index: 9998;
  position: fixed;
  display: none;
  height: 100%;
  width: 100%;
  background: #000;
  top: 0px;
  left: 0px;
}

#to-popup {
  background: rgb(236,236,236);
  color: #333333;
  border-radius: 10px;
  display: none;
  width: 60%;
  height: 70%;
  overflow: auto;
  position: fixed;
  top: 12%;
  z-index: 9999;
  left: 50%;
  margin-left: -31%;
  padding: 15px;


  @media screen and (max-width: 991px){
    left: 5%;
    margin-left: 0;
    width: 90%;
    height: 100%;
  }

  @media screen and (max-width: 768px){
    left: 0;
    margin-left: 0;
    width: 100%;
    height: 100%;
  }
}

#btn-close {
  transition: 0.2s;
  display: block;
  width: 40px;
  height: 40px;
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
  z-index: 9;
  & > i {
    font-size: 40px;
    display: block;
    width: 40px;
    height: 40px;
    &:hover {
      color: firebrick;
    }
  }
}

.popup__title {
  position: absolute;
  top: 0;
  left: 0;
  background-color: $color-main;
  display: block;
  width: 100%;
  z-index: 8;
  font-size: 20px;
  font-weight: 600;
  text-align: center;
  color: $color-bg;
  padding: 5px;
}

#popup-content {
  width: 100%;
  height: 100%;
  min-height: 400px;
}

#loading-title {
  display: block;
  line-height: 400px;
  text-align: center;
}

textarea.form-control {
  min-width: 100%;
  max-width: 100%;
  max-height: 100px;
  min-height: 68px;
}

.form-control-p {
  color: $color-main;
}

