.article-front {

  &:hover {
    .figcaption__btn {
      display: block;
    }
  }

  .article-front__title {
    font-size: 2.5rem;
    font-weight: bold;
  }

  .article-front__read-more {
    color: $color-main;
    font-size: 1.8rem;

    ._text {
      margin-left: 10px;
      color: $color-main;
    }
  }

  @media screen and (max-width: 991px) {
    padding-bottom: 50px;
  }
}

.figcaption__btn {
  color: $color-bg;
  font-size: 60px;
  opacity: .7;
  text-align: center;
  padding-top: 25px;
}

.article-row {
  &:not(:first-child) {
    margin-top: 30px;

    @media screen and (max-width: 767px) {
      margin-top: 50px;
    }
  }
}

.img-post-new {
  width: 100%;
  height: auto;

  @media screen and (max-width: 768px) {
    max-height: 100%;
  }
}

.title-new-post > a {
  padding-top: 10px;
  font-size: 20px;
  color: $color-main;
  font-weight: 600;
  transition: 0.4s;

  &:hover {
    color: $color-brand;
  }
}

.meta-post {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  border-top: 3px solid $color-main;

  .post-date {
    display: block;
    margin: 0;
    background-color: $color-main;
    text-align: center;
    width: 68px;
    height: 64px;

    .day, .month {
      color: $color-bg;
      font-size: 15px;
      font-weight: 600;
      text-transform: uppercase;
      margin: 0;
      line-height: 22px;
    }

    .day {
      font-size: 35px;
      line-height: 30px;
      padding-top: 8px;
    }
  }
}



.breadcrumbs {
  margin-bottom: 20px;
  background-color: $color-main;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: scroll;
  background-position: center top;
  position: relative;
  height: 150px;
  margin-top: 68px;
  z-index: 1;

  &::before {
    position: absolute;
    opacity: 0.9;
    background: rgba(54,52,58,1);
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    content: "";
    z-index: -1;
  }
}

#crumbs {

  padding: 65px 0 30px 0;
  margin: 0;
  background: none;
  text-align: center;

  li {
    list-style: none;
    display: inline-block;
    font-size: 15px;
    color: $color-bg;

    ::after {
      content: " /";
      letter-spacing: 10px;
    }

    & > a {
      font-size: 15px;
      color: $color-bg;
      transition: .4s;
      text-transform: uppercase;
      z-index: 99;
    }

    & > a:hover {
      color: $color-main;
    }
  }
}

/*ARTICLE-PAGE*/
.article-single {
  border-bottom: 2px solid $color-brand;
  padding-top: 20px;
  padding-bottom: 50px;

  .article-single__title {
    font-size: 50px;
    font-weight: bold;
    margin: 0;

    a {
      font-size: inherit;
      color: inherit;

      &:hover {
        color: $color-brand;
        transition: color 300ms ease-in;
      }
    }
  }

  .article-single__date {
    font-weight: 300;
    font-size: 1.3rem;
    font-style: italic;
  }

  .article-single__thumb {
    margin-top: 50px;
  }

  .article-single__content {
    margin-top: 25px;
  }
}

.row-article {
  margin: 0;
}

.article-single--archive {

  .article-single__title {
    font-size: 25px;
  }

  .article-single__thumb {
    margin-top: 20px;
  }
}

.post__single__content {

  img {
    width: 100%;
    height: auto;
  }
}
.aligncenter
{
  display: block;
  margin: 5px auto 5px auto;
}

.media-body {
  padding-left: 10px;

  h2 {
    margin-top: 0;
  }
}

.pagination {
  display: block;
  margin: 30px 0;

  ul {
    text-align: center;
    display: block;
    padding:0;
  }

  li {
    display: inline-block;
  }

  a {
    padding: 10px 17px;
    font-size: 16px;
    display: inline-block;
    border: 1px solid rgba(57,63,76,.5);
  }

  .active {
    a {
      background-color: $color-main;
      color: $color-bg;
      font-weight: 600;
    }
  }
}