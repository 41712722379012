.blog_large {
  padding: 10px;
  margin-bottom: 30px;
  box-shadow: 1px 1px 4px $color-gray;
  box-sizing: border-box;
}

.sw_search  {
  padding: 10px;
  box-shadow: 1px 1px 4px $color-gray;
  box-sizing: border-box;
}

.sw_archives {
  padding: 10px;
  box-shadow: 1px 1px 4px $color-gray;
  box-sizing: border-box;
  margin: 30px 0;
}

.sw_title {

  p {
    font-size: 20px;
    font-weight: 600;
    text-transform: uppercase;
    color: #196BAC;;
  }
}

input.input-text {
  border: 1px solid #eee;
  border-right: 0;
  border-right: medium none;
  height: 40px;
  width: 60%;
  text-align: center;
  color: $color-text;

  &:focus, &:hover, &:active {
    border: 1px solid $color-main !important;
    box-shadow: 1px 2px 3px $color-main !important;
  }
}

#searchsubmit {
  height: 40px;
  background: $color-main;
  color: $color-bg;
  border: medium none;
  padding: 5px 10px;
  margin: 0;
  font-weight: 600;

  &:hover {
    box-shadow: 1px 1px 2px $color-main;
  }
}

.archives_list {
  padding-left: 0;

  > li {
    list-style-type: circle;
    display: block;
    margin-bottom: 10px;




    > h3 > a {
      font-size: 1.6rem;
      font-weight: 600;
      display: inline-block;
      position: relative;
      border-left: 5px solid $color-main;
      padding: 5px 0 10px 5px;

      ._icon {
        margin-right: 10px;
      }

      &::before {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0px;
        width: 0;
        height: 5px;
        background-color: $color-main;
        transition: width 400ms ease-in-out;
      }

      &:hover {

        &::before {
          width: 100%;
        }
      }
    }
  }
}

.btn-hide {
  display: none;
}

.btn-show {
  display: block;
}