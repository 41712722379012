.section__title {
  text-align: center;
  color: $color-text;
  font-size: 34px;
}
.section__title__single {
  background-color: rgb(25,107,172);
  margin: 0;
  padding: 10px;
  color: $color-bg;
  border-bottom: 2px solid ;
}

.title__underlined {
  width: 200px;
  display: block;
  margin: 0 auto;
  border-bottom: 3px solid $color-main;
  text-align: center;
  margin-bottom: 20px;

  & > i {
    font-size: 20px;
    color: $color-main;
  }

  & > i.fa-star-half-o {
    font-size: 15px;
  }
}

.job__list {
  margin: 20px 0;
  padding: 0;

  .job__list__item {
    border: 1px solid $color-gray;
    box-sizing: border-box;
    list-style: outside none none;
    margin: 0;
    padding: 10px;
    position: relative;
    display: inline-block;
    width: 100%;
    transition: 0.2s;
    &:hover {
      background-color: $color-gray;
      border-left: 5px solid $color-main;
    }
  }
}

.job__list__logo{
  border: 1px solid $color-gray;
  box-shadow: none;
  display: block;
  height: auto;
  width: 75px;
  margin-left: 10px;
  float: left;
  vertical-align: middle;
}

.position {
  display: block;
  padding-left: 20px;
  float: left;
  width: 40%;

  & > h3 {
    margin-top: 8px;
    margin-bottom: 5px;
  }
}

.location {
  display: block;
  padding-left: 10px;
  float: left;
  width: 30%;

  & > p {
    padding: 10px 0;
    margin: 0;
    text-align: center;
    font-size: 16px;
    font-weight: 600;
  }
}

.meta-list-job {
  display: block;
  padding-top: 15px;
  padding-right: 10px;
  float: right;
  text-align: right;
  width: 20%;
}
@media screen and (max-width: 991px) {
  .location {
    width: 20%;
  }
}

@media screen and (max-width: 768px) {
  .location {
    width: 100%;

    & > p {
      padding: 10px 0 0 0;
    }
  }

  .meta-list-job {
    float: left;
    padding-left: 10px;
    padding-bottom: 10px;
  }
}

